import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './store';
import './index.css';
import App from './App';
import DebugRouter from './components/DebugRouter';
import * as serviceWorker from './serviceWorker';
import FirebaseContext, { FirebaseProvider } from './services/AuthService';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <DebugRouter isHidden={true} />
      <SnackbarProvider maxSnack={3}>
        <FirebaseProvider>
          <FirebaseContext.Consumer>
            {(props) => !props?.loading && <App />}
          </FirebaseContext.Consumer>
        </FirebaseProvider>
      </SnackbarProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
