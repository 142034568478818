import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  Container,
  CardActions,
  Button,
  CardHeader,
  OutlinedInput,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import { isNil, path, isEmpty } from 'ramda';
import { uploadContent } from '../../actions/Upload';
import NavBar from '../Common/Navbar';
import constants from '../../actions/Upload/constants';
import DisplayMedia from '../Common/DisplayMedia';
import Notifier from '../Common/Notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1B2631',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  card: {
    backgroundColor: '#34495E',
    color: 'white',
    width: '80%',
  },
  text: {
    textAlign: 'left',
  },
  gridItem: {
    textAlign: '-webkit-center',
  },
  whiteContrast: {
    color: theme.palette.getContrastText('#34495E'),
  },
}));

const Upload = (props) => {
  const classes = useStyles();
  const currentFile = useSelector((state) => state.upload.currentFile);
  const isLoading = useSelector((state) => state.upload.isLoading);
  const dispatch = useDispatch();
  const { classes: styles } = props;
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [displayVideo, setDisplayVideo] = useState(false);

  const chooseMeme = (e) => {
    const reader = new FileReader();
    const data = e.target.files[0];
    dispatch({
      type: constants.FILE_CHANGED,
      payload: e.target.files,
    });
    if (!data) {
      setFile(null);
      dispatch({
        type: constants.FILE_CHANGED,
        payload: null,
      });
      return;
    }
    reader.onloadend = function () {
      setFile(reader.result);
      setDisplayVideo(data.type.includes('video'));
    };
    reader.readAsDataURL(data);
  };

  const publish = (e) => {
    e.preventDefault();

    const content = { description };
    dispatch(
      uploadContent(
        content,
        currentFile,
        path(['location', 'state', 'redirectTo'], props),
      ),
    );
    setFile(null);
    setDescription(null);
  };

  useLayoutEffect(() => {
    dispatch({
      type: constants.FILE_CHANGED,
      payload: undefined,
    });
  }, [dispatch]);

  return (
    <>
      <Prompt
        when={Boolean(currentFile)}
        message="Seguro desea dejar la pagina sin publicar el meme?"
      />
      <Notifier />

      <div className={classes.root}>
        <NavBar options={[]} hideUpload />
        <Container maxWidth="md">
          <Grid
            container
            spacing={3}
            direction="column"
            style={{ width: '100%', margin: 0 }}
          >
            <Grid item xs className={classes.gridItem}>
              {isLoading ? (
                <Card className={classes.card}>
                  <CardContent>
                    <CircularProgress />
                  </CardContent>
                </Card>
              ) : (
                <Card className={classes.card}>
                  <CardHeader
                    title={
                      <Button
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        className={classes.whiteContrast}
                      >
                        Elegir meme
                        <input
                          type="file"
                          multiple
                          style={{ display: 'none' }}
                          onChange={chooseMeme}
                        />
                      </Button>
                    }
                  ></CardHeader>
                  {!isNil(currentFile) && !isEmpty(currentFile) && (
                    <DisplayMedia
                      src={file}
                      type={displayVideo ? 'video' : 'image'}
                    />
                  )}
                  <CardContent>
                    <OutlinedInput
                      placeholder="Descripción del meme"
                      fullWidth
                      classes={styles}
                      multiline
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      defaultValue={description}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      component="label"
                      disabled={isNil(currentFile)}
                      startIcon={<SaveIcon />}
                      className={classes.whiteContrast}
                      onClick={publish}
                    >
                      Publicar meme
                    </Button>
                  </CardActions>
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const outlinedInputStyles = (theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'white',
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.getContrastText('#34495E'),
    },
  },
  focused: {},
  notchedOutline: {},
  input: {
    color: theme.palette.getContrastText('#34495E'),
  },
});

export default withStyles(outlinedInputStyles)(Upload);
