import Constants from '../actions/Upload/constants';

const initialState = {
  currentFile: null,
  content: [],
  isLoading: false,
  pagination: { currentPage: 0, lastPage: 0, perPage: 5, total: 0 },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.UPLOAD_FILE:
      return { ...state, currentFile: null };
    case Constants.FILE_CHANGED:
      return { ...state, currentFile: action.payload };
    case Constants.DELETE_FILE:
      return { ...state, currentFile: null };
    case Constants.UPLOADED_CONTENT:
      return { ...state, currentFile: null };
    case Constants.GET_CONTENT:
      return { ...state, content: state.content.concat(action.payload) };
    case Constants.CLEAR_CONTENT:
      return { ...state, content: [] };
    case Constants.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case Constants.IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
