import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
import {
  updateContent,
  getContent,
  clearContent,
  deleteContent,
} from '../../actions/Upload';
import ViewContent from './ViewContent';
import { pathOr } from 'ramda';
import Notifier from '../Common/Notifier';

class Content extends React.PureComponent {
  componentDidMount() {
    this.props.clearContent();
    const content = pathOr('all', ['match', 'params', 'id'], this.props);
    if (!['imagenes', 'videos', 'gifs', 'all'].includes(content)) {
      this.props.history.push('/ver');
    }
    this.props.getContent(content !== 'all' ? content : '', 1, 5);
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentDidUpdate(prevProps) {
    const currentParamId = this.getCurrentParamsId();
    const prevParamId = pathOr('', ['match', 'params', 'id'], prevProps);
    if (currentParamId !== prevParamId) {
      this.props.clearContent();
      this.props.getContent(currentParamId, 1, 5);
    }
  }

  getCurrentParamsId = () => pathOr('', ['match', 'params', 'id'], this.props);

  updateContent = async (contentId, content) => {
    await this.props.updateContent(contentId, content);
  };

  deleteContent = async (id) => {
    await this.props.deleteContent(id);
    this.props.clearContent();
    const currentParamId = this.getCurrentParamsId();
    this.props.getContent(currentParamId, 1, 5);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    const { perPage, currentPage, lastPage } = this.props.pagination;
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      this.props.images.length &&
      !this.props.isLoading &&
      lastPage !== currentPage
    ) {
      this.props.getContent(
        this.getCurrentParamsId(),
        currentPage + 1,
        perPage,
      );
    }
  };

  render() {
    return (
      <>
        <Notifier />
        <ViewContent
          images={this.props.images}
          updateContent={this.updateContent}
          deleteContent={this.deleteContent}
          isLoading={this.props.isLoading}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContent,
      updateContent,
      clearContent,
      deleteContent,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  const { upload } = state;
  return {
    images: upload.content,
    isLoading: upload.isLoading,
    pagination: upload.pagination,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withSnackbar(Content)));
