import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1B2631',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
}));

const Admin = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="p">
        Bienvenido
      </Typography>
      <br />
      <Typography variant="h5" component="a" href="/ver">
        Click aquí para memajes
      </Typography>
      <br />
      <Typography variant="h5" component="a" href="/salir">
        Click aquí para cerrar sesión
      </Typography>
    </div>
  );
};

export default Admin;
