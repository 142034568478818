import React from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import './index.css';
import NavBar, { ScrollToTop } from '../Common/Navbar';
import { NoContent } from './Misc';
import DisplayMedia from '../Common/DisplayMedia';
import Actions, { shareTo, shareToSocialNetwork } from './Actions';
import { useFirebase } from '../../services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1B2631',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  card: {
    backgroundColor: '#34495E',
    color: 'white',
    width: '80%',
  },
  text: {
    textAlign: 'left',
  },
  gridItem: {
    textAlign: '-webkit-center',
    padding: '2vh 0',
  },
}));

const navBarOptions = [
  {
    title: 'todo',
    link: '/ver',
  },
  {
    title: 'imágenes',
    link: '/ver/imagenes',
  },
  {
    title: 'gif',
    link: '/ver/gifs',
  },
  {
    title: 'videos',
    link: '/ver/videos',
  },
];

const ViewContent = ({ images, updateContent, isLoading, deleteContent }) => {
  const classes = useStyles();
  const { isAuthenticated } = useFirebase();

  const share = (image) => {
    shareTo(shareToSocialNetwork.TELEGRAM, image);
  };

  return (
    <>
      <div className={classes.root}>
        <NavBar options={navBarOptions} />
        <Container maxWidth="md">
          <Grid
            container
            spacing={3}
            direction="column"
            style={{ width: '100%', margin: 0 }}
          >
            <SwipeableList>
              {({ className, ...rest }) => (
                <>
                  {images.map((image, idx) => (
                    <SwipeableListItem
                      key={idx}
                      swipeRight={{
                        content: <div>Comparta en Telegram</div>,
                        action: () => share(image),
                      }}
                      {...rest}
                    >
                      <Grid item xs className={classes.gridItem}>
                        <Card className={classes.card}>
                          { isAuthenticated && 
                            <Typography
                              variant="h6"
                              component="a"
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                deleteContent(image._id);
                              }}
                              className={classes.text}
                            >
                              Eliminar
                            </Typography>
                          }
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="p"
                              className={classes.text}
                            >
                              {image.description}
                            </Typography>
                          </CardContent>
                          <DisplayMedia
                            src={image.file.Url}
                            type={image.type}
                            display
                          />
                          <Actions
                            updateContent={updateContent}
                            image={image}
                          />
                        </Card>
                      </Grid>
                    </SwipeableListItem>
                  ))}
                </>
              )}
            </SwipeableList>
            {!isLoading && !images.length && <NoContent />}
            {isLoading && (
              <Grid item className={classes.gridItem}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Container>
        <ScrollToTop style={{ alignSelf: 'flex-end' }} />
      </div>
    </>
  );
};

export default ViewContent;
