import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    textAlign: '-webkit-center',
  },
}));

export const NoContent = () => {
  const params = useParams();
  const classes = useStyles();
  const memes = { gifs: 'gifs', imagenes: 'imágenes', videos: 'videos' };
  return (
    <Grid item className={classes.gridItem}>
      <Typography variant="h4" component="p">
        No hay {memes[params.id]}! {": ' ("}
      </Typography>
    </Grid>
  );
};
