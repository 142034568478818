import { Button, makeStyles, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useState } from 'react';
import { useFirebase } from '../services/AuthService';

const handleSignInError = (reason) => {
  switch (reason) {
    case 'auth/invalid-email':
      return {
        email: 'Correo invalido',
        default: 'Operación Fallida',
      };
    case 'auth/user-disabled':
      return {
        default: 'Usuario deshabilitado',
      };
    case 'auth/user-not-found':
      return {
        default: 'Usuario no encontrado',
      };
    case 'auth/wrong-password':
      return {
        password: 'Contraseña incorrecta',
        default: 'Operación Fallida',
      };
    case 'missing-role':
      return {
        default: 'Permisos insuficientes para ingresar',
      };
    case 'auth/too-many-requests':
      return {
        default:
          'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
      };
    default:
      return {
        default: 'Operación Fallida',
      };
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1B2631',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  formControl: {
    marginBottom: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  whiteContrast: {
    color: `${theme.palette.getContrastText('#34495E')}!important`,
  },
}));

const SignIn = () => {
  const { signIn } = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const classes = useStyles();

  const handleSubmit = async () =>
    signIn(email, password)
      .then(() => undefined)
      .catch((error) => {
        setErrors(handleSignInError(error.code));
      });

  return (
    <div className={classes.root}>
      <h2 className="my-4">Iniciar Sesión</h2>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormControl
          error={Boolean(errors?.email)}
          className={classes.formControl}
        >
          <InputLabel
            htmlFor="email"
            classes={{
              root: classes.whiteContrast,
              focused: classes.whiteContrast,
            }}
          >
            Correo Electrónico
          </InputLabel>
          <Input
            id="email"
            value={email}
            classes={{ root: classes.whiteContrast }}
            onChange={(e) => {
              setErrors(null);
              setEmail(e.target.value);
            }}
            aria-describedby="email-text"
          />
          {errors?.email && (
            <FormHelperText id="email-text">{errors.email}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={Boolean(errors?.password)}
          className={classes.formControl}
        >
          <InputLabel
            htmlFor="password"
            classes={{
              root: classes.whiteContrast,
              focused: classes.whiteContrast,
            }}
          >
            Contraseña
          </InputLabel>
          <Input
            id="password"
            type="password"
            value={password}
            classes={{ root: classes.whiteContrast }}
            onChange={(e) => {
              setErrors(null);
              setPassword(e.target.value);
            }}
            aria-describedby="password-text"
          />
          {errors?.password && (
            <FormHelperText id="password-text">
              {errors.password}
            </FormHelperText>
          )}
        </FormControl>
        {errors?.default && (
          <Typography color="error">{errors.default}</Typography>
        )}
        <Button className={classes.whiteContrast} onClick={handleSubmit}>
          Iniciar sesión
        </Button>
      </form>
    </div>
  );
};

export default SignIn;
