import React from 'react';
import {
  CardActions,
  IconButton,
  Button,
  Typography,
  makeStyles,
  Popover,
  Grid,
} from '@material-ui/core';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ReportIcon from '@material-ui/icons/Report';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    width: '95%',
    display: 'flex',
  },
  actionGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
  shareOption: {
    width: '14vw',
    height: '6vh',
    display: 'block',
    fontSize: '18px',
  },
  reportButton: {
    color: '#34495E',
    backgroundColor: theme.palette.getContrastText('#34495E'),
    '&:hover': {
      color: '#1B2631',
      backgroundColor: theme.palette.getContrastText('#1B2631'),
    },
    margin: '0px 12px',
    padding: '2px 4px',
    fontSize: '12px',
  },
  reportFontSize: {
    fontSize: '12px',
  },
  iconFontSize: {
    fontSize: '80px',
  },
}));

export const actionClick = async (content, action, methodToCall) => {
  switch (action) {
    case actionTypes.LIKE:
      content.likes++;
      break;
    case actionTypes.DISLIKE:
      content.dislikes++;
      break;
    case actionTypes.REPORT:
      content.reports++;
      break;
    default:
      break;
  }
  methodToCall(content._id, content);
};

const actionTypes = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  REPORT: 'report',
};

export const shareToSocialNetwork = {
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  WHATSAPP: 'WHATSAPP',
  TELEGRAM: 'TELEGRAM',
};

export const shareTo = (social, image) => {
  const message = 'Disfrute de este meme y muchos más en www.memajes.com';
  switch (social) {
    case shareToSocialNetwork.FACEBOOK:
      window.open(
        'http://www.facebook.com/sharer.php?u=' +
          encodeURIComponent(image.file.Url) +
          '&t=' +
          encodeURIComponent(message),
        'sharer',
        'toolbar=0,status=0,width=626,height=436',
      );
      break;
    case shareToSocialNetwork.TWITTER:
      window.open(
        'http://www.twitter.com/share?url=' +
          encodeURIComponent(image.file.Url) +
          '&text=' +
          encodeURIComponent(message),
        'sharer',
        'toolbar=0,status=0,width=626,height=436',
      );
      break;
    case shareToSocialNetwork.WHATSAPP:
      const text = encodeURIComponent(`${message}\n${image.file.Url}`);
      window.open(
        `https://api.whatsapp.com/send?text=${text}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436',
      );
      break;
    case shareToSocialNetwork.TELEGRAM:
      window.open(
        `https://t.me/share/url?url=${image.file.Url}&text=${encodeURIComponent(
          message,
        )}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436',
      );
      break;

    default:
      break;
  }
};

const Actions = ({ image, updateContent }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  return (
    <CardActions disableSpacing className={classes.actionContainer}>
      <Grid container spacing={2} justify="space-around" alignItems="center">
        <Grid className={classes.actionGrid}>
          <Typography variant="h6" component="p" className={classes.text}>
            {image.dislikes}
          </Typography>
          <IconButton
            aria-label="dislike"
            onClick={() =>
              actionClick(image, actionTypes.DISLIKE, updateContent)
            }
          >
            <ThumbDownRoundedIcon
              fontSize="large"
              className={classes.iconFontSize}
            />
          </IconButton>
        </Grid>
        <Grid className={classes.actionGrid}>
          <IconButton aria-label="share" onClick={handleClick}>
            <ShareIcon className={classes.iconFontSize} />
          </IconButton>

          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Button
              className={classes.shareOption}
              onClick={() => shareTo(shareToSocialNetwork.FACEBOOK, image)}
            >
              Facebook
            </Button>
            <Button
              className={classes.shareOption}
              onClick={() => shareTo(shareToSocialNetwork.TWITTER, image)}
            >
              Twitter
            </Button>
            <Button
              className={classes.shareOption}
              onClick={() => shareTo(shareToSocialNetwork.WHATSAPP, image)}
            >
              Whatsapp
            </Button>
            <Button
              className={classes.shareOption}
              onClick={() => shareTo(shareToSocialNetwork.TELEGRAM, image)}
            >
              Telegram
            </Button>
            <Button
              className={classes.shareOption}
              onClick={() => navigator.clipboard.writeText(image.file.Url)}
            >
              Copiar Enlace
            </Button>
          </Popover>
          <Button
            size="small"
            className={classes.reportButton}
            classes={{ startIcon: classes.reportFontSize }}
            startIcon={<ReportIcon />}
            onClick={() =>
              actionClick(image, actionTypes.REPORT, updateContent)
            }
          >
            Report
          </Button>
          <Typography variant="h6" component="p" className={classes.text}>
            {image.reports}
          </Typography>
        </Grid>
        <Grid className={classes.actionGrid}>
          <IconButton
            aria-label="like"
            onClick={() => actionClick(image, actionTypes.LIKE, updateContent)}
          >
            <ThumbUpRoundedIcon
              fontSize="large"
              className={classes.iconFontSize}
            />
          </IconButton>
          <Typography variant="h6" component="p" className={classes.text}>
            {image.likes}
          </Typography>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export default Actions;
