import axios from 'axios';
import { push } from 'connected-react-router';
import { path, pathOr } from 'ramda';
import Constants from './constants';
import { enqueueSnackbar } from '../Snackbar';
import querystring from 'querystring';
import { authInstance } from '../../services/AuthService';

const API = process.env.REACT_APP_BACKEND_URL;

export const uploadFile = (file) => async (dispatch) => {
  try {
    const data = new FormData();
    data.append('file', file);

    const response = await axios.post(`${API}/api/file`, data);

    dispatch({
      type: Constants.UPLOAD_FILE,
      payload: path(['data'], response),
    });
    return {
      fileId: path(['data', '_id'], response),
      type: path(['data', 'type'], response),
    };
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: pathOr('Error.', ['response', 'data', 'message'], error),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export const deleteFile = (file) => async (dispatch) => {
  try {
    if (!path(['_id'], file)) return;

    await axios.delete(`${API}/api/file/${file._id}`);

    dispatch({
      type: Constants.DELETE_FILE,
    });
  } catch (error) {}
};

export const uploadContent = (content, files, redirectTo) => async (
  dispatch,
) => {
  try {
    dispatch(isLoading(true));
    let uploadCount = 0;
    const postContent = async (file) => {
      try {
        const { fileId, type } = await dispatch(uploadFile(file));
        await axios.post(`${API}/api/content`, {
          ...content,
          file: fileId,
          type,
        });
        uploadCount++;
      } catch (error) {}
    };
    if (files.length > 1) {
      for (var i = 0; i < files.length; i++) {
        const file = files.item(i);
        await postContent(file);
      }
    } else {
      await postContent(files.item(0));
    }

    dispatch({ type: Constants.UPLOADED_CONTENT });
    dispatch(isLoading(false));
    dispatch(push(redirectTo || '/ver'));
    dispatch(
      enqueueSnackbar({
        message: `Éxito, ${uploadCount}/${files.length} nuevo(s) memes.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(isLoading(false));
    dispatch(
      enqueueSnackbar({
        message: pathOr('Error.', ['response', 'data', 'message'], error),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export const getContent = (content, page = 1, perPage = 5) => async (
  dispatch,
) => {
  dispatch(isLoading(true));
  try {
    const queryObj = { page, perPage };
    const contentTypes = { imagenes: 'image', gifs: 'gif', videos: 'video' };
    if (content) {
      queryObj.type = contentTypes[content];
      if (queryObj.type !== contentTypes.imagenes) {
        queryObj.perPage = 2;
      }
    }
    const query = querystring.stringify(queryObj);
    const { data: response } = await axios.get(`${API}/api/content?${query}`);

    dispatch({
      type: Constants.SET_PAGINATION,
      payload: Object.keys(response)
        .filter((key) => key !== 'data')
        .reduce((obj, key) => {
          obj[key] = response[key];
          return obj;
        }, {}),
    });

    dispatch({
      type: Constants.GET_CONTENT,
      payload: path(['data'], response),
    });

    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
  }
};

export const updateContent = (contentId, content) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    await axios.put(`${API}/api/content/${contentId}`, content);

    dispatch({ type: Constants.UPDATE_CONTENT });
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    dispatch(
      enqueueSnackbar({
        message: pathOr('Error.', ['response', 'data', 'message'], error),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export const deleteContent = (id) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    if (authInstance && authInstance.currentUser === null) {
      dispatch(push('/entrar'));
    }
    const token = await authInstance.currentUser.getIdToken();
    await axios.delete(`${API}/api/content/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: Constants.UPDATE_CONTENT });
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    dispatch(
      enqueueSnackbar({
        message: pathOr('Error.', ['response', 'data', 'message'], error),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export const isLoading = (loading) => ({
  type: Constants.IS_LOADING,
  payload: loading,
});

export const clearContent = () => ({
  type: Constants.CLEAR_CONTENT,
});
