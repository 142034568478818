import React from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1B2631',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  card: {
    backgroundColor: '#34495E',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  text: {
    margin: '30px 0px',
  },
}));

const Home = () => {
  const classes = useStyles();
  const links = [
    { to: '/ver', displayText: 'Ver memes' },
    { to: '/subir', displayText: 'Subir memes' },
  ];
  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ width: '100%' }}>
        <Grid item xs={2} />
        {links.map((link) => (
          <Grid item xs key={link.displayText}>
            <Link to={link.to} className={classes.link}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.text}
                  >
                    {link.displayText}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default Home;
