import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import Routes from './routes';
import GA from './components/GoogleAnalytics';

const App = () => {
  const [preview, setPreview] = useState(true); // false
  if (!preview) {
    return (
      <div className="App">
        <header className="App-header">
          <p>Muy Pronto aquí,</p>
          <p>Esperalo</p>
          <button onClick={() => setPreview((pvw) => !pvw)}>
            Ver version en desarrollo.
          </button>
        </header>
      </div>
    );
  }
  return (
    <div className="App">
      <CssBaseline />
      {GA.init() && <GA.RouteTracker />}
      <Routes />
    </div>
  );
};

export default App;
