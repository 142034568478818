import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Divider,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const Drawer = (props) => {
  const [state, setState] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {props.options.map((option, idx) => (
          <ListItem
            button
            key={idx}
            onClick={
              option.link ? () => history.push({ pathname: option.link }) : null
            }
          >
            <ListItemText primary={option.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {!props.hideUpload && (
        <List>
          <ListItem
            button
            onClick={() =>
              history.push({
                pathname: '/subir',
                state: { redirectTo: location.pathname },
              })
            }
          >
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary={'Subir'} />
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor={'right'}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default Drawer;
