import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBMEFk13-ee6wOaalQ5aVhWwFgpfjA-K7g',
  authDomain: 'memajes.firebaseapp.com',
  projectId: 'memajes',
  storageBucket: 'memajes.appspot.com',
  messagingSenderId: '976665986382',
  appId: '1:976665986382:web:ce5131d71ea026b5c48a1f',
  measurementId: 'G-790BWBQBF6',
};

export const FirebaseContext = React.createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export let authInstance = null;
export let firebaseApp = null;

export const FirebaseProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState();

  useEffect(() => {
    const initFirebase = () => {
      firebaseApp = firebase.apps.length
        ? firebase.apps[0]
        : firebase.initializeApp(config);
      const authHook = firebase.auth();
      authInstance = authHook;
      setAuth(authHook);
      authHook.onAuthStateChanged((userChanged) => {
        if (userChanged) {
          setIsAuthenticated(true);
          setUser(userChanged);
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
        setLoading(false);
      });
    };

    initFirebase();
  }, []);

  const signOut = () => auth.signOut();

  const signIn = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const createUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

  const sendPasswordResetEmail = (email) => auth.sendPasswordResetEmail(email);

  const updatePassword = (password) =>
    auth.currentUser.updatePassword(password);

  return (
    <FirebaseContext.Provider
      value={{
        loading,
        user,
        isAuthenticated,
        createUserWithEmailAndPassword,
        sendPasswordResetEmail,
        signIn,
        signOut,
        updatePassword,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
