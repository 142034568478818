import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginRight: '10px',
  },
  link: {
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  items: {
    justifyContent: 'space-around',
  },
}));

const Options = (props) => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.items}>
        <Grid item />
        {props.options.map((option, idx) => (
          <Grid item key={idx}>
            <Typography variant="h6" className={classes.title}>
              {option.link ? (
                <Link className={classes.link} to={option.link}>
                  {option.title}
                </Link>
              ) : (
                option.title
              )}
            </Typography>
          </Grid>
        ))}
        <Grid item />
      </Grid>

      {!props.hideUpload && (
        <Link
          to={{
            pathname: '/subir',
            state: { redirectTo: location.pathname },
          }}
        >
          <Button color="inherit" startIcon={<CloudUploadIcon />}>
            Subir
          </Button>
        </Link>
      )}
    </>
  );
};

export default Options;
