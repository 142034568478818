import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CardMedia } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    width: '95%',
    maxHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  media: {
    maxWidth: '100%',
    maxHeight: '70vh',
    objectFit: 'contain',
    display: 'block',
  },
  video: {
    maxWidth: '100%',
    maxHeight: '70vh',
    width: '100%',
    objectFit: 'contain',
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

const DisplayMedia = ({ type, src, display }) => {
  const classes = useStyles();
  return (
    <div className={classes.mediaContainer}>
      {type === 'video' ? (
        <video
          className={display ? classes.video : classes.media}
          controls
          src={src}
        />
      ) : (
        <CardMedia className={classes.media} component="img" image={src} />
      )}
    </div>
  );
};

DisplayMedia.propTypes = {
  type: PropTypes.oneOf(['video', 'image', 'gif']).isRequired,
  src: PropTypes.string,
};

export default DisplayMedia;
