import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useFirebase } from '../services/AuthService';

const PrivateRoute = (props) => {
  const { loading, isAuthenticated } = useFirebase();
  if (loading) return null;

  return isAuthenticated === true ? (
    <Route {...props} />
  ) : (
    <Redirect to="/entrar" />
  );
};

export default PrivateRoute;
