import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Content from './components/View/Content';
import Upload from './components/Upload';
import { useFirebase } from './services/AuthService';
import SignIn from './components/SignIn';
import PrivateRoute from './components/PrivateRoute';
import Admin from './components/Admin';

const Routes = () => {
  const { isAuthenticated, signOut } = useFirebase();
  return (
    <Switch>
      {isAuthenticated && <Redirect from="/entrar" to="/admin" />}
      <Route exact path="/entrar" component={SignIn} />
      <PrivateRoute
        exact
        path="/salir"
        render={() => {
          signOut();
          return null;
        }}
      />
      <Route path="/" exact component={Home} />
      <Route path="/ver" exact component={Content} />
      <Route path="/ver/:id" exact component={Content} />
      <Route path="/subir" exact component={Upload} />
      <PrivateRoute path="/admin" exact component={Admin} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
