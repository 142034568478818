export default {
  UPLOAD_FILE: 'UPLOAD_FILE',
  DELETE_FILE: 'DELETE_FILE',
  FILE_CHANGED: 'FILE_CHANGED',
  UPLOADED_CONTENT: 'UPLOADED_CONTENT',
  GET_CONTENT: 'GET_CONTENT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  IS_LOADING: 'IS_LOADING',
  CLEAR_CONTENT: 'CLEAR_CONTENT',
  SET_PAGINATION: 'SET_PAGINATION',
};
